import request from "@/common/utils/request";
import Vue from "vue";

export function doEdit(data) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/updateWxMpRoute",
    method: "post",
    data
  });
}
export function getList(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/listWxMpRoute",
    method: "get",
    params
  });
}
