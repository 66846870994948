<!-- xuwencheng  2022年6年29日18:00  这是我的wenzhangbianji -->
<!-- 编辑 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destory-on-close="true"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
  >
    <el-form ref="form" label-width="200px" :model="form" :rules="rules">
      <el-form-item label="icon图片名称" prop="notes">
        <el-input v-model="form.notes" placeholder="请输入icon图片名称" />
      </el-form-item>

      <el-form-item label="icon图片图片" prop="icon">
        <el-input v-model="form.icon" style="display: none" />
        <el-upload
          v-model="form.icon"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :before-upload="beforeAvatarUpload"
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          :on-success="handleAvatarSuccess"
          :show-file-list="false"
        >
          <img v-if="imageUrl" class="avatar" :src="imageUrl" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="小程序路径" prop="url">
        <el-input v-model="form.url" placeholder="请输入小程序路径" />
      </el-form-item>
    </el-form>
    <el-button class="baocun" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </el-dialog>

  <!-- 增加 -->
</template>
      
      <script>
/* eslint-disable*/
import { doEdit } from "@/api/icon";
import util from "@/common/utils/util.js";
import { getToken } from "@/common/utils/auth";

//import { number } from 'echarts'
export default {
  name: "search",

  data() {
    return {
      selectedOptions: [],
      token: "",
      imageUrl: "",
      value: true,
      value1: true,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      options1: [],
      options2: [],
      options3: [],
      valueone: "",
      valuetwo: "",
      form: {},
      rules: {
        notes: [
          { required: true, trigger: "blur", message: "请输入icon图片名称" },
        ],

        url: [
          { required: true, trigger: "change", message: "请输入小程序路径" },
        ],

        icon: [{ required: true, trigger: "change", message: "请添加图片" }],
      },
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.icon = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return true;
    },

    showEdit(row) {
      if (!row) {
        this.title = "添加";
      } else {
        this.title = "编辑";
        this.form = Object.assign({}, row);
        let t = getToken();
        this.token = t;
        this.imageUrl = row.icon;
      }

      this.dialogFormVisible = true;
    },
    close() {
      let row = {};
      this.form = Object.assign({}, row);
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "修改中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = doEdit;

          func(this.form)
            .then((res) => {
              let title = "修改";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.dialogFormVisible = false;
                  this.close();
                  this.$emit("fetch-data");
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
      <style>
/* .huakuai {
          position: relative;
          left: 96%;
        } */
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
.baocun {
  width: 100%;
  height: 100%;
}
</style>
      